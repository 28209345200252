import Axios from 'axios'
import $store from '@/store'

const axios = Axios.create({
    baseURL:'https://hagonne.cc/api',
    // baseURL:'/api',
});

//请求拦截
axios.interceptors.request.use(config => {
    config.headers.operation_type = 1;
    return config;
});

//接收拦截
axios.interceptors.response.use(response => {
    const data  = response.data;
    if(data.code === 609){
        $store.dispatch('logout');
    }

    return response;
});

axios.defaults.withCredentials = true;  //允许后端设置cookie

export default axios;