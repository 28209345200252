import Home from '@/components/common/Home'

const items = [
    {
        path:'/',
        name:'Home',
        component:Home,
        children:[
            {
                path:'/dashboard',
                name:'dashboard',
                meta:{
                    title:'首页',
                },
                component:()=>import('@/views/Dashboard')
            },
            {
                path:'/companylist',
                name:'companylist',
                meta:{
                    title:'公司管理',
                },
                component:()=>import('@/views/CompanyList')
            },
            {
                path:'/userlist',
                name:'userlist',
                meta:{
                    title:'用户管理',
                },
                component:()=>import('@/views/UserList')
            },
            {
                path:'/rolelist',
                name:'rolelist',
                meta:{
                    title:'角色管理',
                },
                component:()=>import('@/views/RoleList')
            },
            {
                path:'/permissionlist',
                name:'permissionlist',
                meta:{
                    title:'权限管理',
                },
                component:()=>import('@/views/PermissionList')
            },
            {
                path:'/productclassified',
                name:'productclassified',
                meta:{
                    title:'产品分类',
                },
                component:()=>import('@/views/ProductClass')
            },
            {
                path:'/productlist',
                name:'productlist',
                meta:{
                    title:'产品列表',
                },
                component:()=>import('@/views/ProductList')
            },
            {
                path:'/storehouse',
                name:'storehouse',
                meta:{
                    title:'库房列表',
                },
                component:()=>import('@/views/StoreHouse')
            },
            {
                path:'/loginlog',
                name:'loginlog',
                meta:{
                    title:'登录日志',
                },
                component:()=>import('@/views/LoginLog')
            },
            {
                path:'/operationlog',
                name:'operationlog',
                meta:{
                    title:'操作日志',
                },
                component:()=>import('@/views/OperationLog')
            },
            {
                path:'/devicelist',
                name:'devicelist',
                meta:{
                    title:'设备列表',
                },
                component:()=>import('@/views/DeviceList')
            },
        ],
    }
];



export {
    items,
}