<template>
    <el-dialog v-model="dialogVisible" title="告警列表" draggable :close-on-click-modal="false">
        <div class="table-div">
            <el-table v-loading="loading" :data="tableData" border>
                <el-table-column prop="id" label="序号" align="center" width="80"></el-table-column>
                <el-table-column prop="device_number" label="设备编号" align="center" width="120"></el-table-column>
                <el-table-column prop="branch" label="通道" align="center" width="80"></el-table-column>
                <el-table-column prop="current_value" label="数值" align="center" width="150"></el-table-column>
                <el-table-column label="发生时间" align="center" width="160">
                    <template #default="scope">
                        {{timestampToTime(scope.row.warning_time*1000)}}
                    </template>
                </el-table-column>
                <el-table-column prop="current_max_value" label="预警上限" align="center" width="160"></el-table-column>
                <el-table-column prop="current_min_value" label="预警下限" align="center" width="160"></el-table-column>
            </el-table>
        </div>
        <div class="pagination-div">
            <el-pagination
                    v-model:current-page="curPage"
                    :page-size="10"
                    :background="background"
                    layout="total, prev, pager, next"
                    :total="total"
                    @current-change="handleCurrentChange"
            />
        </div>
    </el-dialog>
</template>

<script>
    import {defineComponent, ref,reactive,getCurrentInstance,watch,} from 'vue';
    import {timestampToTime} from '@/libs/common'

    export default defineComponent({
        name: "personal-dialog",
        setup(){
            const currentInstance = getCurrentInstance();
            const {$axios,$message} = currentInstance.appContext.config.globalProperties;
            const dialogVisible = ref(false);   // 控制显示
            const loading = ref(false); // load提示
            const tableData = ref([]);  // 列表数据
            const curPage = ref(1);
            const total = ref(0);

            // 监听页码变化
            async function handleCurrentChange(num){
                await getData(num);
            }

            async function getData(cur){
                loading.value = true;
                try {
                    const {data} = await $axios({
                        url:'/device/alarmList',
                        method:'GET',
                        params:{
                            pageNumber:cur,
                        }
                    });

                    loading.value = false;
                    if(data.code==200){
                        tableData.value = data.data;
                        total.value = data.total;
                    }else{
                        $message.error(data.msg);
                    }
                }catch (e) {
                    loading.value = false;
                    $message.error('网络连接出错，请稍后重试');
                }
            }

            // 显示弹出框
            async function open() {
                dialogVisible.value = true;
                curPage.value = 1;
                getData(1);
            }

            return{
                dialogVisible,
                loading,
                tableData,
                open,
                timestampToTime,
                curPage,
                total,
                handleCurrentChange,
            }
        }
    })
</script>

<style scoped>

</style>