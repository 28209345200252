import {createRouter,createWebHistory} from 'vue-router'

/*const routes = [
    {
        path:'/login',
        name:'login',
        component:()=>import('@/views/Login')
    },
    {
        path:'/',
        name:'home',
        component:()=>import('@/components/common/Home'),
        children:[
            {
                path:'/dashboard',
                name:'dashboard',
                component:()=>import('@/views/Dashboard')
            },
            {
                path:'/companylist',
                name:'companylist',
                component:()=>import('@/views/CompanyList')
            },
            {
                path:'/userlist',
                name:'userlist',
                component:()=>import('@/views/UserList')
            },
            {
                path:'/rolelist',
                name:'rolelist',
                component:()=>import('@/views/RoleList')
            },
            {
                path:'/permissionlist',
                name:'permissionlist',
                component:()=>import('@/views/PermissionList')
            },
            {
                path:'/productclassified',
                name:'productclassified',
                component:()=>import('@/views/ProductClass')
            },
            {
                path:'/productlist',
                name:'productlist',
                component:()=>import('@/views/ProductList')
            },
            {
                path:'/storehouse',
                name:'storehouse',
                component:()=>import('@/views/StoreHouse')
            },
            {
                path:'/loginlog',
                name:'loginlog',
                component:()=>import('@/views/LoginLog')
            },
            {
                path:'/operationlog',
                name:'operationlog',
                component:()=>import('@/views/OperationLog')
            },
            {
                path:'/devicelist',
                name:'devicelist',
                component:()=>import('@/views/DeviceList')
            },
        ],
    }
];*/

const routes = [
    {
        path:'/login',
        name:'login',
        meta:{
            title:'登录',
        },
        component:()=>import('@/views/Login')
    },
];

const router = createRouter({
    history:createWebHistory('/'),
    routes,
});



export default router;
