<template>
    <div class="sidebar">
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#324157" text-color="#bfcbd9" active-text-color="#20a0ff" router>
            <el-menu-item v-for="item in navs" :index="item.index">
                <el-icon v-if="item.index=='dashboard'"><home-filled /></el-icon>
                <el-icon v-else-if="item.index=='companylist'"><office-building /></el-icon>
                <el-icon v-else-if="item.index=='userlist'"><user /></el-icon>
                <el-icon v-else-if="item.index=='rolelist'"><avatar /></el-icon>
                <el-icon v-else-if="item.index=='permissionlist'"><key /></el-icon>
                <el-icon v-else-if="item.index=='productclassified'"><grid /></el-icon>
                <el-icon v-else-if="item.index=='productlist'"><goods /></el-icon>
                <el-icon v-else-if="item.index=='storehouse'"><school /></el-icon>
                <el-icon v-else-if="item.index=='loginlog'"><tickets /></el-icon>
                <el-icon v-else-if="item.index=='operationlog'"><memo /></el-icon>
                <el-icon v-else-if="item.index=='devicelist'"><Platform /></el-icon>
                <template #title>{{item.title}}</template>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
    import {defineComponent,onMounted,ref,computed,getCurrentInstance} from 'vue';
    import { HomeFilled,Key,Grid,Platform,Avatar,User,Goods,OfficeBuilding,Tickets,Memo,School } from '@element-plus/icons-vue'

    export default defineComponent({
        name: "Sidebar",
        setup(){
            const currentInstance = getCurrentInstance();
            const {$router,$route,$store} = currentInstance.appContext.config.globalProperties;
            const navs = ref([]);
            const navs2 = [
                {
                    icon: HomeFilled,
                    index: "dashboard",
                    title: "首页",
                },
                {
                    icon: OfficeBuilding,
                    index: "companylist",
                    title: "公司管理"
                },
                {
                    icon: User,
                    index: "userlist",
                    title: "用户管理"
                },
                {
                    icon: Avatar,
                    index: "rolelist",
                    title: "角色列表"
                },
                {
                    icon: Key,
                    index: "permissionlist",
                    title: "权限列表"
                },
                {
                    icon: Grid,
                    index: "productclassified",
                    title: "产品分类"
                },
                {
                    icon: Goods,
                    index: "productlist",
                    title: "产品列表"
                },
                {
                    icon: School,
                    index: "storehouse",
                    title: "库房列表"
                },
                {
                    icon: Tickets,
                    index: "loginlog",
                    title: "登录日志"
                },
                {
                    icon: Memo,
                    index: "operationlog",
                    title: "操作日志"
                },

                {
                    icon: Platform,
                    index: "devicelist",
                    title: "设备列表"
                },
            ];

            const arr = $router.getRoutes();
            const newArr = [];

            for (let i = 0;i < arr.length;i++){
                for(let j = 0;j < navs2.length;j++){
                    if(arr[i].name == navs2[j].index){
                        newArr.push(navs2[j]);
                    }
                }
            }
            navs.value = newArr;

            const collapse = computed(()=>{
                return $store.getters.getCollapse;
            });
            const onRoutes = computed(()=>{
                return $route.path.replace("/", "");
            });

            return {
                navs,
                collapse,
                onRoutes,
            }
        },
    })
</script>

<style scoped>
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 0;
        overflow-y: scroll;
    }
    .sidebar::-webkit-scrollbar {
        width: 0;
    }
    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 250px;
    }
    .sidebar > ul {
        height: 100%;
    }
</style>