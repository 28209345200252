<template>
    <el-dialog v-model="dialogVisible" title="账户详情" draggable :close-on-click-modal="false">
        <el-table :data="tableData" border>
            <el-table-column prop="username" label="用户名" align="center" width="200"></el-table-column>
            <el-table-column prop="role_name" label="角色" align="center" width="200"></el-table-column>
            <el-table-column prop="company_name" label="公司" align="center" width="200"></el-table-column>
            <el-table-column prop="warn_switch" label="预警提醒" align="center" width="200">
                <template #default="scope">
                    <el-switch v-model="scope.row.warn_switch" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" @change="changeStatus(scope.row)"></el-switch>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
    import {defineComponent, ref,reactive,getCurrentInstance,watch,} from 'vue';

    export default defineComponent({
        name: "personal-dialog",
        setup(){
            const currentInstance = getCurrentInstance();
            const {$axios,$message} = currentInstance.appContext.config.globalProperties;
            const dialogVisible = ref(false);   // 控制显示
            const tableData = ref([]);

            // 显示弹出框
            async function open() {
                try {
                    const {data} = await $axios({url:'/user/getInfo',method:'POST'});
                    if(data.code==200){
                        tableData.value = data.data;
                        dialogVisible.value = true;
                    }else{
                        $message.error(data.msg);
                    }
                }catch (e) {
                    $message.error('网络连接出错，请稍后重试');
                }
            }

            // 改变状态触发
            async function changeStatus(row) {
                const {warn_switch} = row;
                try {
                    const {data} = await $axios({
                        url:'/user/editWarnStatus',
                        method:'post',
                        data:{
                            warn_switch:warn_switch
                        },
                    });
                    if(data.code === 200){
                        $message({
                            message: data.msg,
                            type: 'success',
                        });
                    }else{
                        tableData.value[0].warn_switch = warn_switch?0:1;
                        $message.error(data.msg);
                    }
                }catch (e) {
                    console.log(e);
                    $message.error('网络连接失败，请稍后重试');
                    tableData.value[0].warn_switch = warn_switch?0:1;
                }
            }

            return{
                dialogVisible,
                tableData,
                open,
                changeStatus,
            }
        }
    })
</script>

<style scoped>

</style>