<template>
    <div class="header">
        <div class="logo">
            <img class="pic1" src="../../assets/imgs/哈工精密(图形)白色.png" alt="" />
            <img class="pic2" src="../../assets/imgs/哈工精密(文字)白色.png" alt="" />
            <span> - 物联网平台</span>
        </div>
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChange">
            <el-icon v-if="!collapse"><Fold /></el-icon>
            <el-icon v-else><Expand /></el-icon>
        </div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 消息中心 -->
                <div class="btn-bell" @click="popAlarm">
                    <el-tooltip effect="dark" content="预警中心" placement="bottom">
                        <el-icon style="vertical-align: middle;size:20px;"><Bell /></el-icon>
                    </el-tooltip>
                </div>
                <!-- 用户头像 -->
                <div class="user-avator">
                    <img src="../../assets/imgs/img.jpg" />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="personal">个人资料</el-dropdown-item>
                            <el-dropdown-item command="editpass">修改密码</el-dropdown-item>
                            <el-dropdown-item divided command="loginOut">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    import {defineComponent, onMounted,ref, computed,reactive, getCurrentInstance} from 'vue'
    export default defineComponent({
        name: "Header",
        setup(props,context){
            const currentInstance = getCurrentInstance();
            const {$axios,$message,$store} = currentInstance.appContext.config.globalProperties;


            // 侧边栏折叠
            function collapseChange() {
                $store.dispatch("handleCollapse", !collapse.value);
            }

            const collapse = computed(()=>{
                return $store.getters.getCollapse;
            });
            const username = computed(()=>{
                return $store.getters.getUsername;
            });

            // 弹出告警
            function popAlarm(){
                context.emit('popAlarm');
            }

            // 悬停点击事件
            async function handleCommand(val){
                if(val === 'loginOut'){    // 退出
                    try{
                        const {data} = await $axios({
                            url: '/logout',
                            method:'POST',
                        });
                        if(data.code == 200){
                            $message({
                                message:data.msg,
                                type: 'success',
                            });
                            $store.dispatch('logout');
                        }else{
                            $message.error(data.msg);
                        }
                    }catch (e) {
                        console.log(e);
                        $message.error('网络连接错误，请稍后重试');
                    }
                }else if(val === 'personal'){  // 弹出个人信息
                    context.emit('popDialog','personal');
                }else if(val === 'editpass'){   // 修改密码
                    context.emit('popDialog','editpass');
                }
            }

            onMounted(async ()=>{
                if (document.body.clientWidth < 1500) {
                    collapseChange();
                }
            });

            return{
                collapse,
                username,
                collapseChange,
                handleCommand,
                popAlarm,
            }
        },
    })
</script>

<style scoped>
    @font-face {
        font-family: 'test';
        src: url("../../assets/fonts/字魂35号-经典雅黑.ttf") format('truetype');
    }

    .header {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        font-size: 22px;
        color: #fff;
    }
    .collapse-btn {
        float: left;
        padding: 0 21px;
        cursor: pointer;
        line-height: 70px;
    }
    .header .logo {
        float: left;
        width: 300px;
        line-height: 70px;
        padding-left: 50px;
    }
    .header .logo span{
        font-family: 'test';
        color: #ffffff;
        opacity: .9;
        position: relative;
        top: -4px;
    }
    .header .logo img{
        height: auto;
        vertical-align: middle;
    }
    .header .logo .pic1{
        width: 55px;
    }
    .header .logo .pic2{
        width: 90px;
        margin-left: 10px;
    }


    .header-right {
        float: right;
        padding-right: 50px;
    }
    .header-user-con {
        display: flex;
        height: 70px;
        align-items: center;
    }
    .btn-bell{
        cursor: pointer;
    }
    .user-name {
        margin-left: 10px;
    }
    .user-avator {
        margin-left: 40px;
    }
    .user-avator img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .el-dropdown-link {
        color: #fff;
        cursor: pointer;
    }
</style>