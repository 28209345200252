<template>
    <el-dialog v-model="dialogFormVisible" title="修改密码" draggable :close-on-click-modal="false">
        <el-form :model="ruleForm.form" :rules="ruleForm.rules" ref="ruleFormRef" status-icon>
            <el-form-item label="用户名" :label-width="formLabelWidth" prop="username">
                <el-input v-model.trim="ruleForm.form.username" disabled placeholder="用户名" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="原密码" :label-width="formLabelWidth" prop="oldPass">
                <el-input v-model.trim="ruleForm.form.oldPass" placeholder="请输入原密码" type="password" minlength="6" maxlength="18" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="新密码" :label-width="formLabelWidth" prop="newPass">
                <el-input v-model.trim="ruleForm.form.newPass" placeholder="请输入新密码" type="password" minlength="6" maxlength="18" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="再次确认" :label-width="formLabelWidth" prop="newPass2">
                <el-input v-model.trim="ruleForm.form.newPass2" placeholder="请再次输入新密码" type="password" minlength="6" maxlength="18" autocomplete="off"/>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="done(ruleFormRef)" :loading-icon="Eleme" :loading="loadingState">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
    import {defineComponent, ref,reactive,getCurrentInstance,watch,} from 'vue';
    import { Eleme } from '@element-plus/icons-vue'

    export default defineComponent({
        name: "editpass-dialog",
        setup(){

            const currentInstance = getCurrentInstance();
            const {$axios,$message,$store} = currentInstance.appContext.config.globalProperties;
            const loadingState = ref(false);
            const formLabelWidth = ref(120);    // 文字宽度
            const ruleFormRef = ref(null);
            const dialogFormVisible = ref(false);   // 控制显示

            const ruleForm = reactive({  // form表单
                form:{
                    username:'',
                    oldPass: '',
                    newPass: '',
                    newPass2: '',
                },
                rules:{
                    oldPass:[
                        { required:true,message:'原密码不能为空哦',trigger:'blur'},
                        { min: 6, max: 18, message: '6-18个字符之间', trigger: 'blur' },
                        // { pattern:/^(?=.*[a-z])(?=.*\d)[\s\S]{6,18}$/,message:'密码格式错误',trigger:'blur' },    // 6-18位，必须是数字与字母的组合
                    ],
                    newPass:[
                        { required:true,message:'新密码不能为空哦',trigger:'blur'},
                        { min: 6, max: 18, message: '6-18个字符之间', trigger: 'blur' },
                        // { pattern:/^(?=.*[a-z])(?=.*\d)[\s\S]{6,18}$/,message:'密码格式错误',trigger:'blur' },    // 6-18位，必须是数字与字母的组合
                    ],
                    newPass2:[
                        { required:true,message:'请再次输入新密码',trigger:'blur'},
                        { min: 6, max: 18, message: '6-18个字符之间', trigger: 'blur' },
                        // { pattern:/^(?=.*[a-z])(?=.*\d)[\s\S]{6,18}$/,message:'密码格式错误',trigger:'blur' },    // 6-18位，必须是数字与字母的组合
                    ],
                },
            });

            // 监听显示隐藏重置数据
            watch(dialogFormVisible,flag => {
                if(!flag){
                    ruleFormRef.value.resetFields();
                    ruleForm.form = {
                        username:'',
                        oldPass: '',
                        newPass: '',
                        newPass2: '',
                    };
                }
            });

            // 显示弹出框
            async function open() {
                ruleForm.form.username = $store.getters.getUsername;
                dialogFormVisible.value = true;
            }

            // 执行提交
            function done() {
                ruleFormRef.value.validate(async valid => {
                    if(valid){
                        if(ruleForm.form.newPass !== ruleForm.form.newPass2){
                            $message.error('两次密码不一致');
                            return;
                        }
                        loadingState.value = true;
                        try {
                            const {data} = await $axios({
                                url:'/user/editPass',
                                method:'post',
                                data:{
                                    oldPass:ruleForm.form.oldPass,
                                    newPass:ruleForm.form.newPass,
                                },
                            });
                            loadingState.value = false;
                            if(data.code === 200){
                                dialogFormVisible.value = false;

                                // 弹出提示
                                $message({
                                    message: data.msg,
                                    type: 'success',
                                });
                            }else{
                                $message.error(data.msg);
                            }
                        }catch (e) {
                            console.log(e);
                            loadingState.value = false;
                            $message.error('网络连接失败，请稍后重试');
                        }

                    }else{
                        return false;
                    }
                });
            }

            return{
                dialogFormVisible,
                ruleForm,
                ruleFormRef,
                formLabelWidth,
                loadingState,
                Eleme,
                open,
                done,
            }
        }
    })
</script>

<style scoped>

</style>