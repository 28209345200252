import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import router from '@/router'

import store from '@/store'

import axios from '@/axios'





import App from './App.vue'

const app = createApp(App);
app.config.globalProperties.$axios = axios;
for(const [key,component] of Object.entries(ElementPlusIconsVue)){
    app.component(key,component);
}

app.use(router).use(store).use(ElementPlus).mount('#app');
