<template>
    <div class="home">
        <v-header @popDialog="popDialog" @popAlarm="popAlarmFn" />
        <v-sidebar />
        <div class="content-box" :class="{'content-collapse':collapse}">
            <div class="content">
                <transition name="move" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
        <personal ref="personalDialog" />
        <editpass ref="editPassDialog" />
        <alarmlist ref="alarmListDialog" />
    </div>
</template>

<script>
    import {defineComponent,getCurrentInstance,ref,computed} from 'vue';

    import vHeader from '@/components/common/Header'
    import vSidebar from '@/components/common/Sidebar'
    import personal from '@/components/common/personal'
    import editpass from '@/components/common/editpass'
    import alarmlist from '@/components/common/alarmList'

    export default defineComponent({
        name: "Home",
        components:{
            vHeader,
            vSidebar,
            personal,
            editpass,
            alarmlist,
        },
        setup(){
            const currentInstance = getCurrentInstance();
            const {$store,$notify} = currentInstance.appContext.config.globalProperties;
            const personalDialog = ref(null);   // 账户详情对话框
            const editPassDialog = ref(null);   // 修改密码对话框
            const alarmListDialog = ref(null);   // 告警列表对话框
            let ws = null;     // websocket实例
            // 创建ws连接
            if (!window.WebSocket) {
                window.WebSocket = window.MozWebSocket;
            }
            if (!$store.getters.getCompanyNumber && window.WebSocket) {
                ws = new WebSocket(`${$store.getters.getWS}/message?sessionID=${$store.getters.getToken}&username=${$store.getters.getUsername}`);

                ws.onopen = event => {
                    // 发送参数
                    // ws.send(JSON.stringify({device_number}));
                };
                ws.onmessage = event => {
                    $notify({
                        title: '设备告警：',
                        message: event.data,
                        position: 'bottom-right',
                        type:'warning',
                    });
                };
                ws.onclose = event => {
                    console.log('已断开', event);
                    //
                };
            }

            // 弹出对话框
            function popDialog(val) {
                switch (val) {
                    case 'personal':
                        personalDialog.value.open();
                        break;
                    case 'editpass':
                        editPassDialog.value.open();
                        break;
                }
            }

            // 弹出告警列表
            async function popAlarmFn() {
                alarmListDialog.value.open();
            }

            const collapse = computed(()=>{
                return $store.getters.getCollapse;
            });

            return {
                personalDialog,
                editPassDialog,
                alarmListDialog,
                popDialog,
                popAlarmFn,
                collapse,
            }
        },
    })
</script>

<style scoped>

</style>